@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

* { margin: 0; padding: 0; }

:root {

    --text-color: #1A1A1A;
    --secondary-color: #3D89AE;
    --note-color: #b0bbc1;
    --bg-color-main: #ffffff;
    --bg-color-secondary: #F7F7F7;
    --bg-color-third: #DBE2EC;

    --logo-text-size: 80px;
    --heading-text-size: 36px;
    --subheading-text-size: 28px;
    --section-text-size: 24px;

    --main-text-size: 16px;
    --secondary-text-size: 14px;
    --side-text-size: 12px;
    --small-text-size: 10px;

    --half-padding: 15px;
    --main-padding: 30px;
}

.university {
    --url1: #6822B0;
    --url2: #32B9F1;
    --url3: #32B9F1;
}

.city {
    --url1: #E44184;
    --url2: #6822B0;
    --url3: #32B9F1;
}

body {
    background-color: var(--bg-color-main);
    font-weight: 400;
    color: var(--text-color);
    font-family: "Open Sans", Roboto, SansSerif, serif;
    line-height: 1.1;
    font-size: var(--main-text-size);
}

h1, h2, h3, h4, h5 .logo {
    font-family: Montserrat, Roboto, SansSerif, serif;
    font-weight: 600;
}

strong {
    font-weight: 600;
}

.logo {
    font-size: var(--logo-text-size)
}

h1 {
    font-size: var(--heading-text-size)
}

h2 {
    font-size: var(--subheading-text-size)
}

h3 {
    font-size: 22px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
    font-weight: normal;
}

p {
    font-size: var(--main-text-size);
}

div, p {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.heading-font {
    font-family: Montserrat, Roboto, SansSerif, serif;
}

small {
    font-size: 10px;
    color: var(--note-color);
}

ul, ol {
    list-style: none;
}

.liElement {
    list-style: none;
}

li {
    line-height: 2;
}

li::before {
    content: "• ";
    margin-right: 10px;
    color: var(--url1);
}

a {
    color: var(--url3);
}

.displayMobile {
    display: none;
}

.displayWeb {
    display: block;
}

.disabledClick {
    cursor: default;
    pointer-events: none;
    opacity: .3;
}

.enabledClick {
    cursor: pointer;
    pointer-events: auto;
    opacity: 1;
}

/*
/ LANDING
 */

.landing-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.landing-container {
    width: 100%;
    max-width: 1100px;
}

.landing-header {
    width: 100%;
    display: flex;
    padding: 0 var(--main-padding);
    max-width: 1100px;
}

.landing-header-image-box {
    position: relative;
    width: 100%;
}

.landing-header-image {
    position: absolute;
    margin-top: 100px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    top: 0;
}

.landing-header-image img {
    margin-right: -100px;
    max-width: 700px;
}

.separator {
    width: 100%;
    height: 4px;
    background-color: var(--url3);
}

.separator1px {
    height: 1px;
    margin: 0;
    padding: 0;
    border-bottom: var(--bg-color-third) 1px solid;
}

.landing-gray-box {
    width: 100%;
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.landing-white-box {
    width: 100%;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.landing-gray-box-image {
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

/*
/ MAIN CONTAINER
 */

.bg-left {
    width: 100%;
    min-height: 100vh;
    background-color: var(--secondary-color);
    background-size: cover;
}

.bg-right {
    min-height: 100vh;
    margin-left: 20px;
    background-color: var(--bg-color-secondary);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 20px 30px;
}

.errorPanel {
    padding: 20px;
    border-radius: 4px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #ff0000;
    color: #ffffff;
}

.infoPanel {
    padding: 20px;
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff4a6;
    color: #000000;
    z-index: 100;
}

@-webkit-keyframes blink {
    50% { border-left: #ff0000 2px solid; }
}
@keyframes blink {
    50% { border-left: #ff0000 2px solid; }
}

.errorBorder {
    border-radius: 4px;
    padding: 10px;
    width: 100%;
    border-left: transparent 2px solid;
    animation: blink .5s step-end infinite alternate;
    -webkit-animation: blink .5s step-end infinite alternate;
}

.errorBorderNoBlink {
    border-radius: 4px;
    padding-left: 10px;
    width: 100%;
    border-left: #ff0000 2px solid;
}

/*
/ LOGIN AREA
 */

.login-area{
    width: 100%;
    max-width: 400px;
    min-width: 320px;
    display: inline-block;
    padding: 20px;
    margin-top: 10px;
    background-color: var(--bg-color-main);
    box-shadow: 0 0 20px rgba(0,0,0,0.15);
}

/*
/ ADMIN CONTAINERS
 */

.admin-main-container {
    padding: var(--main-padding);
}

.admin-header {

}

.admin-container {
    display: flex;
}

.admin-form {
    width: 100%;
    margin-right: 40px;
}

.services {
    min-width: 750px;
}

.admin-preview {
   display: block;
}

.admin-phone-preview {
    background-color: var(--bg-color-main);
    width: 375px;
    padding: 20px;
}

.save-container {
    position: fixed;
    bottom: 10px;
    padding: 15px;
    text-align: right;
    background-color: var(--bg-color-main);
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
}


.bottom-space {
    height: 80px;
}

/*
/ SERVICE CONTAINERS
 */

.service-item-left {
    border-bottom: #eaeaea 1px solid;
    border-left: #eaeaea 1px solid;
    padding-left: 15px;
    padding-right: 20px;
}

.service-item {
    border-bottom: #eaeaea 1px solid;
}

.service-container {
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 20px;
    background-color: var(--bg-color-main);
    min-width: 290px;
}

.service-heading {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 15px;
    border-bottom: var(--url1) 1px solid;
    padding-bottom: 5px;
}

/*
/ TABBAR
 */


.tabbar {
    margin: 20px 0;
    background-color: var(--bg-color-main);
    border-bottom: var(--url3) 1px solid;
    display: flex;
}

.tabbar-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.tab {

    padding: 0 20px;
    color: var(--secondary-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    min-height: 40px;
}

.tab:hover {
    background-color: #E6F5FB;
    color: var(--secondary-color);
}

.tab label {
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    color: var(--url3);
    font-size: var(--secondary-text-size);
}

.selected-tab {
    background-color: var(--url3);
    color: var(--bg-color-main);
    cursor: auto;
}

.selected-tab label{
    color: var(--bg-color-main);
    cursor: auto;
}

.selected-tab:hover {
    background-color: var(--url3);
    color: var(--bg-color-main);
}


/*
/ PREVIEW TEXT SECTION
 */

.text-block {
    margin-bottom: 20px;
    white-space: pre-wrap;
}

.text-block p {
    margin-top: 5px;
    font-size: var(--secondary-text-size);
}

/*
.text-block div * {
    margin: 10px 0;
}

 */

.text-block span {
    font-size: var(--side-text-size);
}

.service-preview p {
    margin-top: 15px;
    margin-bottom: 15px;
}

.service-preview h3 {
    margin-top: 15px;
    margin-bottom: 20px;
}

.service-preview button {
    margin-right: 10px;
    margin-bottom: 10px;
}

@media (max-width:680px) {
    .admin-preview {
        display: none;
    }
}

/*
/ OPERATIONS SECTION
 */

.operations-timerow {
    display: flex;
    flex-wrap: wrap;
    border-bottom: var(--bg-color-third) 2px solid;
    padding-bottom: 20px;
}

.grid-bg {
    border-radius: 6px;
    background-color: var(--bg-color-third);
}

.validation-bg-on {
    border: red 1px solid;
}

.operations-check-bg {
    width: 25px;
    height: 25px;
    background-color: var(--bg-color-third);
    border-radius: 6px;
    text-align: center;
    line-height: 25px;
    cursor: pointer;
    border: var(--bg-color-main) 1px solid;
}

.operations-check-bg-selected {
    background-color: var(--url3);
}


/*
/ Media Queries
 */

@media (min-width: 0px) and (max-width:450px) {
    :root {
        --logo-text-size: 46px;
        --heading-text-size: 26px;
        --subheading-text-size: 20px;
        --section-text-size: 18px;
        --main-text-size: 15px;

        --main-padding: 20px;
        --half-padding: 10px;
    }

    .displayMobile {
        display: block;
    }

    .displayWeb {
        display: none;
    }

    .admin-form {
        width: 100%;
        margin-right: 0;
    }

    .services {
        min-width: 290px;
    }

}

@media (min-width: 0px) and (max-width:330px) {
    :root {
        --logo-text-size: 40px;
        --heading-text-size: 24px;
        --subheading-text-size: 18px;
        --section-text-size: 16px;
        --main-text-size: 15px;

        --main-padding: 15px;
        --half-padding: 8px;
    }

}
