.multiSelectContainer input  {
    width: unset;
    height: unset;
    box-shadow: unset;
    -ms-box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    padding : unset;
    border-radius: unset;
    border: unset;
    outline: none;
}
